/**
 * Constants used in display decision logic
 */
export const TAX_CONSTANTS = {
  SELECT: {
    VERTICAL: 'nbcnews/vertical/select',
    SECTION_PRIME_DAY: 'nbcnews/section/shopping/amazon-prime-day',
  },
  MSNBC: {
    VERTICAL: 'nbcnews/vertical/msnbc',
    // Nebula will set 'nbcnews/section/msnbc' as the primaryVertical for MSNBC articles,
    // we will need to check it as well to make sure that legacy articles are rendering correctly.
    VERTICAL_TAXONOMY_FALLBACK: 'nbcnews/section/msnbc',
    SECTION_OPINION: 'nbcnews/section/opinion',
    SECTION_COLUMNIST: 'nbcnews/section/featured-columnist',
  },
  THINK: {
    VERTICAL: 'nbcnews/vertical/think',
  },
  TODAY: {
    GIFT_GUIDE: 'today/section/shop/gift-guides',
    SECTION_FOOD: 'today/section/food',
    SECTION_HOME: 'today/section/home',
    SECTION_PETS: 'today/section/pets',
    SECTION_STYLE: 'today/section/style',
    SECTION_SHOP: 'today/section/shop',
    SECTION_TMRW_TODAY: 'today/section/tmrw',
    SHOP_BEAUTY_TOPIC: 'today/section/shop/beauty-products',
    SHOP_BEDDING_LABEL: 'today/label/shop-bedding',
    SHOP_BESTSELLERS_LABEL: 'today/label/shop-best-sellers',
    SHOP_CELEBRITY_FAVORITES_LABEL: 'today/label/shop-celebrity-favorites',
    SHOP_CLOTHING_LABEL: 'today/label/shop-clothing',
    SHOP_COUNTER_TOP_APPLIANCES_LABEL: 'today/label/shop-countertop-appliances',
    SHOP_DEALS_LABEL: 'today/label/shop-deals',
    SHOP_FOOD_GROCERIES_LABEL: 'today/label/shop-food-groceries',
    SHOP_KITCHEN_TOPIC: 'today/section/shop/kitchen',
    SHOP_PET_SUPPLIES_LABEL: 'today/label/shop-pet-supplies',
    SHOP_STORAGE_ORGANIZATION_LABEL: 'today/label/shop-storage-organization',
    SHOP_STYLE_TOPIC: 'today/section/style/style',
    SHOP_TECH_TOPIC: 'today/section/shop/tech-gadgets',
    SHOP_WELLNESS_LABEL: 'today/label/shop-wellness',
    SECTION_ABOUT: 'today/section/about',
    SECTION_GAMES: 'today/section/games',
    SECTION_HEALTH: 'today/section/health',
    SECTION_INFORMATION: 'today/section/information',
    SECTION_LIFE: 'today/section/life',
    SECTION_MONEY: 'today/section/money',
    SECTION_PAID_CONTENT: 'today/section/paid-content',
    SECTION_PARENTING_GUIDES: 'today/section/parenting-guides',
    SECTION_PARENTS: 'today/section/parents',
    SECTION_PODCASTS: 'today/section/podcasts',
    SECTION_POPCULTURE: 'today/section/popculture',
    SECTION_SEASON_OF_KINDNESS: 'today/section/season-of-kindness',
    SECTION_KINDNESS: 'today/section/kindness',
    SECTION_TOGETHER: 'today/section/together',
    SECTION_VISIT_TODAY: 'today/section/visit-today',

    SHOP_ALL_DAY_TOPIC: 'today/label/shop-all-day',
    SHOP_AMAZON_BESTSELLERS_TOPIC: 'today/section/shop/amazon-bestsellers',
    SHOP_AMAZON_PRIME_DAY_TOPIC: 'today/section/shop/amazon-prime-day',
    SHOP_BEAUTY_PRODUCTS_TOPIC: 'today/section/shop/beauty-products',
    SHOP_BESTSELLERS_TOPIC: 'today/section/shop/bestsellers',
    SHOP_CONSUMER_REPORTS_TOPIC: 'today/section/shop/consumer-reports',
    SHOP_FASHION_TOPIC: 'today/section/shop/fashion',
    SHOP_GIFT_GUIDES_TOPIC: 'today/section/shop/gift-guides',
    SHOP_HOME_DECOR_TOPIC: 'today/section/shop/home-decor',
    SHOP_KIDS_BABIES_TOPIC: 'today/section/shop/kids-babies',
    SHOP_LIFESTYLE_FITNESS_TOPIC: 'today/section/shop/lifestyle-fitness',
    SHOP_PET_SUPPLIES_TOPIC: 'today/label/shop-pet-supplies',
    SHOP_POP_UP_SHOP_TOPIC: 'today/section/shop/pop-up-shop',
    SHOP_SHOP_BLACK_FRIDAY_DEALS_TOPIC: 'today/section/shop/shop-black-friday-deals',
    SHOP_SHOP_WOMEN_OWNED_BUSINESS_TOPIC: 'today/section/shop/shop-women-owned-business',
    SHOP_TECH_GADGETS_TOPIC: 'today/section/shop/tech-gadgets',
    SHOP_TIKTOK_TOPIC: 'today/label/shop-tiktok',
    SHOP_TODAY_HOLIDAY_PLAZA_TOPIC: 'today/section/shop/today-holiday-plaza',

    SHOP_DIET_LABEL: 'today/label/diet',
    SHOP_WOMENS_DAY_LABEL: 'today/label/womens-day',
  },
};

const electionSlugs = [
  '2020-election',
  '2022-election',
  '2024-election',
  'elecciones-estados-unidos-2022',
  'elecciones-estados-unidos-2024',
];

/**
 * Test if sections contain given string slug value
 * @param {Array} sections
 * @param {String} keyword
 * @returns {Boolean}
 */
export const sectionContainSlug = (sections, keyword) => {
  if (!Array.isArray(sections)) {
    return false;
  }
  return sections.some((section) => (section && section.slug === keyword));
};

/**
 * Test if given value meets minimum requirement of being taxonomy term
 * @param {Object} term
 * @returns {Boolean}
 */
export const isTaxonomyTerm = (term) => !!term
  && typeof term === 'object'
  && typeof term.id === 'string';

/**
 * Pull [key] from taxonomy term structure and return a flat array
 * @param {Object} taxonomy
 * @returns {Array}
 */
const extractTermsValuesByKey = (taxonomy, key) => {
  // Return term[key]
  if (isTaxonomyTerm(taxonomy)) {
    return taxonomy[key];
  }
  let terms = [];
  if (typeof taxonomy === 'object') {
    // Object containing all taxonomy terms
    Object.values(taxonomy).forEach((row) => {
      // Is array of multiple terms
      if (Array.isArray(row) && row.length) {
        terms = [...terms, ...extractTermsValuesByKey(row, key)];
        // Is single term
      } else if (isTaxonomyTerm(row)) {
        terms.push(row[key]);
      }
    });
  }
  // Return uniques
  return terms.filter((value, index, self) => self.indexOf(value) === index);
};

/**
 * Pull ids from taxonomy term structure and return a flat array
 * @param {Object} taxonomy
 * @returns {Array}
 */
export const extractTermIds = (taxonomy) => extractTermsValuesByKey(taxonomy, 'id');

/**
 * Pull paths from taxonomy term structure and return a flat array
 * @param {Object} taxonomy
 * @returns {Array}
 */
export const extractTermPaths = (taxonomy) => extractTermsValuesByKey(taxonomy, 'path');

/**
 * Pull slug from taxonomy term structure and return a comma separated string
 * @param {Object} taxonomy
 * @returns {string}
 */
// eslint-disable-next-line max-len
export const extractTermSlugsAsCommaSeparatedList = (taxonomy) => extractTermsValuesByKey(taxonomy, 'slug').toString();

/**
 * Pulls slug from taxonomy term structure and compares to list of election slugs
 * @param {string} taxonomySlug Slug of primary taxonomy (NOT taxonomy path)
 * @returns {boolean}
 */
export function isElectionsTaxonomy(taxonomySlug) {
  return electionSlugs.some((electionSlug) => electionSlug === taxonomySlug);
}
