import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import SaveBorder from 'assets/icons/save-border.svg';
import SaveBorderThick from 'assets/icons/save-border-thick.svg';
import HeartBorderThick from 'assets/icons/heart-border-thick.svg';


import styles from './styles.module.scss';

export function ButtonRibbon({ handleClick }) {
  return (
    <button
      data-testid="save-button"
      type="button"
      className={styles.button}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleClick();
      }}
    >
      <span className={classNames('icon', 'icon-save-filled', styles.icon, styles.iconSave)} />
      <img
        className={classNames('icon', styles.icon, styles.iconSaveHover, styles.iconRibbon)}
        src={SaveBorderThick}
        alt="Save hover state"
        data-testid="icon-save-border-thick"
      />
      <span
        className={classNames('icon', 'icon-save-thick', styles.icon, styles.iconSaveAlt)}
        data-testid="icon-save-thick"
      />
    </button>
  );
}


ButtonRibbon.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export function ButtonHeart({ handleClick }) {
  return (
    <button
      data-testid="save-button"
      type="button"
      className={styles.button}
      onClick={(e) => {
        e.preventDefault();
        handleClick();
      }}
    >
      <span className={classNames('icon', 'icon-heart-filled', styles.icon, styles.iconSave)} />
      <img
        className={classNames(styles.icon, styles.iconSaveHover, styles.iconHeart)}
        src={HeartBorderThick}
        alt="Save hover state"
        data-testid="icon-heart"
      />
      <span
        className={classNames('icon', 'icon-heart-thick', styles.icon, styles.iconSaveAlt)}
        data-testid="icon-save-thick"
      />
    </button>
  );
}

ButtonHeart.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export function ButtonWithText({ handleClick, isItemBookmarked, navbarPlacement }) {
  return (
    <>
      <button data-testid="save-button" type="button" className={styles.button} onClick={handleClick}>
        <span className={
          classNames(
            'icon',
            'icon-save-filled',
            styles.icon,
            styles.iconSave,
            {
              [styles.navbarPlacement]: navbarPlacement,
            },
          )
        }
        />
        <img
          data-testid="icon-ribbon"
          className={
            classNames(
              'icon',
              styles.icon,
              styles.iconSaveHover,
              styles.iconRibbon,
              {
                [styles.navbarPlacement]: navbarPlacement,
              },
            )
          }
          src={SaveBorder}
          alt="Save hover state"
        />
        <span
          className={
            classNames(
              'icon',
              'icon-save',
              styles.icon,
              styles.iconSaveAlt,
              {
                [styles.navbarPlacement]: navbarPlacement,
              },
            )
          }
        />
        {!isItemBookmarked && !navbarPlacement ? (
          <span
            className={styles.text}
            data-testid="nbc-save-button-text-save"
          >
            Save
          </span>
        ) : null}
      </button>
      {isItemBookmarked && !navbarPlacement ? (
        <span className={styles.text} data-testid="nbc-save-button-text-goto">
          {'Go to '}
          <a className="share__saved-link" href="/my-news#activity">
            Saved
          </a>
        </span>
      ) : null}
    </>
  );
}

ButtonWithText.defaultProps = {
  navbarPlacement: false,
};

ButtonWithText.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isItemBookmarked: PropTypes.bool.isRequired,
  navbarPlacement: PropTypes.bool,
};
