import React from 'react';
import PropTypes from 'prop-types';

export class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    errorHandler: PropTypes.func,
    errorLogger: PropTypes.func,
  };

  static defaultProps = {
    errorHandler: null,
    errorLogger: null,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error, info) {
    const { children = { }, errorLogger } = this.props;
    const childCompName = children.type?.displayName;

    // doing this the quick & dirty way because we shouldn't be logging very much
    // throughout our app, and using other methods loses context of line numbers
    // eslint-disable-next-line no-console
    if (typeof console !== 'undefined' && typeof console.error === 'function') {
      // eslint-disable-next-line no-console
      console.error(`error in component: ${childCompName}`, error, info);
    }

    if (errorLogger) errorLogger(error, info);

    this.setState({ hasError: true });
  }

  render() {
    const { hasError } = this.state;
    const { children, errorHandler } = this.props;

    if (hasError) {
      if (errorHandler) {
        return errorHandler() || null;
      }
      return null;
    }

    return children;
  }
}
