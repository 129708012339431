import PropTypes from 'prop-types';

const CTAWrapper = ({ condition, wrapper, children }) => (
  condition ? wrapper(children) : children
);

export { CTAWrapper };

CTAWrapper.defaultProps = {
  children: {},
};

CTAWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
