import { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const modalRootEl = typeof document === 'object' ? document.getElementById('modal-root') : null;
const contentEl = typeof document === 'object' ? document.getElementById('content') : null;

class Modal extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    preventBackgroundScroll: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    preventBackgroundScroll: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      scrollPosition: null,
    };
    this.el = typeof window !== 'undefined' && document.createElement('div');
  }

  componentDidMount() {
    const { preventBackgroundScroll } = this.props;

    if (modalRootEl) modalRootEl.appendChild(this.el);

    if (preventBackgroundScroll) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({ scrollPosition: window.pageYOffset });
      // see src/assets/stylesheets/global/_classes.scss for `verticalScrollDisabled` class
      contentEl.classList.add('verticalScrollDisabled');
    }
  }

  componentDidUpdate(prevProps) {
    const { preventBackgroundScroll } = this.props;

    if (prevProps.preventBackgroundScroll !== preventBackgroundScroll) {
      if (preventBackgroundScroll) {
        contentEl.classList.add('verticalScrollDisabled');
      } else {
        contentEl.classList.remove('verticalScrollDisabled');
      }
    }
  }

  componentWillUnmount() {
    const { scrollPosition } = this.state;
    const { preventBackgroundScroll } = this.props;

    if (modalRootEl) modalRootEl.removeChild(this.el);

    if (preventBackgroundScroll) {
      contentEl.classList.remove('verticalScrollDisabled');
      window.scrollTo(0, scrollPosition);
    }
  }

  render() {
    const { children } = this.props;
    return modalRootEl && children
      ? ReactDOM.createPortal(children, this.el)
      : null;
  }
}

export default Modal;
